<template>
  <validation-observer ref="formSortiesFrontieresTerrestres">
    <b-row>
      <b-col lg="12" class="pb-2">
        <div>
          <b-card>
            <b-card-body>
              <b-row class="mb-1">
                <b-col md="12">
                  <b-form-group>
                    <validation-provider #default="{ errors }" rules="required">
                      <div class="label">
                        Commissariat de police
                        <small>(Réservé à l’agent de police)</small>
                      </div>
                      <b-form-input
                        v-model="form.commissariat"
                        type="text"
                        placeholder=""
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <!-- body -->
                <b-col lg="12" class="pb-2">
                  <div>
                    <b-card>
                      <b-card-body>
                        <b-row v-if="false" class="mb-1">
                          <b-col md="12">
                            <b-form-group>
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <div class="label">
                                  Commissariat de police
                                  <small>(Réservé à l’agent de police)</small>
                                </div>
                                <b-form-input
                                  v-model="form.commissariat"
                                  type="text"
                                  placeholder=""
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <b-row>
                          <!-- Date sortie -->
                          <b-col md="12">
                            <b-form-group
                              label-cols-md="12"
                              label="Date de sortie du voyageur"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required|beforeOrToday"
                              >
                                <b-form-input
                                  v-model="form.date"
                                  lang="fr-CA"
                                  type="date"
                                  placeholder="Veuillez entrer la date à laquelle vous êtes entrées"
                                  class="form-control"
                                  :max="today"
                                />
                                <!-- <flat-pickr
                              v-model="form.date"
                              class="form-control"
                              placeholder="Date"
                            /> -->
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>

                          <!-- pays residence -->
                          <b-col md="12">
                            <b-form-group
                              label-cols-lg="12"
                              label="Pays de résidence"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <SelectCountriesList
                                  v-model="form.paysResidence"
                                  :inputValue="form.paysResidence"
                                  :placeholder="'Sélectionnez le pays de résidence'"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <!-- Nationalite -->
                          <b-col md="12">
                            <b-form-group
                              label-cols-lg="12"
                              label="Nationalité"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <SelectCountriesList
                                  v-model="form.nationalite"
                                  :inputValue="form.nationalite"
                                  :placeholder="'Sélectionnez le pays de votre nationalité'"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <!-- pays destination -->
                          <b-col md="12">
                            <b-form-group
                              label-cols-lg="12"
                              label-class="regular-label"
                              label="PAYS DE DESTINATION"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <SelectCountriesList
                                  v-model="form.paysDestination"
                                  :inputValue="form.paysDestination"
                                  :placeholder="'Sélectionnez le pays de destination'"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>

                          <!-- mode hebergement benin -->
                          <b-col v-if="false" md="12">
                            <b-form-group
                              label-cols-lg="12"
                              label="Mode d'hébergement au benin"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-radio-group
                                  v-model="form.modeHebergementBenin.radio"
                                >
                                  <b-form-radio
                                    v-for="(item, index) in modesHebergement"
                                    :key="'modeHebergementBenin' + index"
                                    name="modeHebergementBenin"
                                    :value="item"
                                  >
                                    {{ item.text }}
                                    <validation-provider
                                      v-if="
                                        item.code &&
                                        form.modeHebergementBenin.radio &&
                                        form.modeHebergementBenin.radio.code ===
                                          item.code
                                      "
                                      v-slot="{ errors }"
                                      rules="required"
                                    >
                                      <b-form-input
                                        v-model="
                                          form.modeHebergementBenin.input
                                        "
                                        class="d-inline"
                                        type="text"
                                        placeholder="Veuillez préciser"
                                      />
                                      <small class="text-danger">{{
                                        errors[0]
                                      }}</small>
                                    </validation-provider>
                                  </b-form-radio>
                                </b-form-radio-group>

                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>

                          <!-- SEJOUR A L’ETRANGER POUR LES RESIDENTS DU BENIN -->
                          <b-col
                            v-if="isBeninResident(form.paysResidence)"
                            md="12"
                          >
                            <div>
                              SÉJOUR A L’ÉTRANGER POUR LES RÉSIDENTS DU BENIN
                            </div>
                            <b-row class="ml-2">
                              <b-col md="12">
                                <b-form-group
                                  label-cols-lg="12"
                                  label="Durée de séjour à l'étranger"
                                >
                                  <validation-provider
                                    v-slot="{ errors }"
                                    rules="required"
                                  >
                                    <b-form-input
                                      v-model.number="
                                        form.sejourEtranger.dureeSejour
                                      "
                                      v-digits-only
                                      placeholder="Durée de séjour à l'étranger"
                                      type="number"
                                    />
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </b-form-group>

                                <!-- <b-form-group
                              label-cols-lg="12"
                              label="Durée de séjour à l'étranger"
                            >

                              <div class="d-flex align-items-center gap-10">
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required"
                                  class="d-inline-block"
                                >
                                  <b-form-input
                                    v-model="
                                      dureeSejourMois
                                    "
                                    type="number"
                                  />

                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                                <div class="d-inline-block">
                                  mois
                                </div>
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required"
                                  class="d-inline-block"
                                >
                                  <b-form-input
                                    v-model="
                                      dureeSejourJours
                                    "
                                    type="number"
                                  />

                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                                <div class="d-inline-block">
                                  jours
                                </div>
                              </div>

                            </b-form-group> -->
                              </b-col>
                              <b-col md="12">
                                <b-form-group
                                  label-cols-lg="12"
                                  label="Motif principal du voyage à l'étranger"
                                >
                                  <validation-provider
                                    v-slot="{ errors }"
                                    rules="required"
                                  >
                                    <b-form-radio-group
                                      v-model="
                                        form.sejourEtranger.motifVoyage.radio
                                      "
                                    >
                                      <b-form-radio
                                        v-for="(
                                          item, index
                                        ) in motifPrincipalVoyage"
                                        :key="
                                          'motifPrincipalVoyageEtranger' + index
                                        "
                                        name="motifPrincipalVoyageEtranger"
                                        :value="item"
                                      >
                                        {{ item.text }}
                                        <validation-provider
                                          v-if="
                                            item.code &&
                                            form.sejourEtranger.motifVoyage
                                              .radio &&
                                            form.sejourEtranger.motifVoyage
                                              .radio.code === item.code
                                          "
                                          v-slot="{ errors }"
                                          rules="required"
                                        >
                                          <b-form-input
                                            v-model="
                                              form.sejourEtranger.motifVoyage
                                                .input
                                            "
                                            class="d-inline"
                                            type="text"
                                            placeholder="Veuillez préciser"
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </validation-provider>
                                      </b-form-radio>
                                    </b-form-radio-group>

                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="12">
                                <b-form-group
                                  label-cols-lg="12"
                                  label="Mode d'hébergement à l'étranger"
                                >
                                  <validation-provider
                                    v-slot="{ errors }"
                                    rules="required"
                                  >
                                    <b-form-radio-group
                                      v-model="
                                        form.sejourEtranger.modeHebergement
                                          .radio
                                      "
                                      disabled-field="disabled"
                                    >
                                      <b-form-radio
                                        v-for="(
                                          item, index
                                        ) in modesHebergement"
                                        :key="
                                          'modesHebergementEtranger' + index
                                        "
                                        name="modesHebergementEtranger"
                                        :value="item"
                                      >
                                        {{ item.text }}
                                        <validation-provider
                                          v-if="
                                            item.code &&
                                            form.sejourEtranger.modeHebergement
                                              .radio &&
                                            form.sejourEtranger.modeHebergement
                                              .radio.code === item.code
                                          "
                                          v-slot="{ errors }"
                                          rules="required"
                                        >
                                          <b-form-input
                                            v-model="
                                              form.sejourEtranger
                                                .modeHebergement.input
                                            "
                                            class="d-inline"
                                            type="text"
                                            placeholder="Veuillez préciser"
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </validation-provider>
                                      </b-form-radio>
                                    </b-form-radio-group>

                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </b-col>

                          <!-- SEJOUR AU BENIN POUR LES NON-RESIDENTS DU BENIN (à remplir seulement par les non- résidents du Bénin) -->
                          <b-col
                            v-if="!isBeninResident(form.paysResidence)"
                            md="12"
                          >
                            <div class="custom-label">
                              SÉJOUR AU BENIN POUR LES NON-RÉSIDENTS DU BENIN
                            </div>
                            <b-row class="ml-2">
                              <b-col md="12">
                                <b-form-group
                                  label-cols-lg="12"
                                  label="Durée de séjour à l'étranger"
                                >
                                  <validation-provider
                                    v-slot="{ errors }"
                                    rules="required"
                                  >
                                    <b-form-input
                                      v-model.number="
                                        form.sejourBenin.dureeSejour
                                      "
                                      v-digits-only
                                      placeholder="Durée de séjour à l'étranger"
                                      type="number"
                                    />
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </b-form-group>

                                <!-- <b-form-group
                              label-cols-lg="12"
                              label-class="regular-label"
                              label="DUREE DE SEJOUR AU BENIN (Jours-Mois)"
                            >
                              <div class="d-flex align-items-center gap-10">
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required"
                                  class="d-inline-block"
                                >
                                  <b-form-input
                                    v-model.number="
                                      dureeSejourJours
                                    "
                                    type="number"
                                  />

                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                                <div class="d-inline-block">
                                  jours
                                </div>
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required"
                                  class="d-inline-block"
                                >
                                  <b-form-input
                                    v-model.number="
                                      dureeSejourMois
                                    "
                                    type="number"
                                  />

                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                                <div class="d-inline-block">
                                  mois
                                </div>
                              </div>
                            </b-form-group> -->
                              </b-col>
                              <b-col v-if="form.sejourBenin" md="10">
                                <b-form-group
                                  label-cols-lg="12"
                                  label-class="regular-label"
                                  label="MOTIF PRINCIPAL DU VOYAGE AU BENIN"
                                >
                                  <validation-provider
                                    v-slot="{ errors }"
                                    rules="required"
                                  >
                                    <b-form-radio-group
                                      v-model="
                                        form.sejourBenin.motifVoyage.radio
                                      "
                                    >
                                      <b-form-radio
                                        v-for="(
                                          item, index
                                        ) in motifPrincipalVoyage"
                                        :key="
                                          'motifPrincipalVoyageBenin' + index
                                        "
                                        name="motifPrincipalVoyageBenin"
                                        :value="item"
                                      >
                                        {{ item.text }}
                                        <validation-provider
                                          v-if="
                                            item.code &&
                                            form.sejourBenin.motifVoyage
                                              .radio &&
                                            form.sejourBenin.motifVoyage.radio
                                              .code === item.code
                                          "
                                          v-slot="{ errors }"
                                          rules="required"
                                        >
                                          <b-form-input
                                            v-model="
                                              form.sejourBenin.motifVoyage.input
                                            "
                                            class="d-inline"
                                            type="text"
                                            placeholder="Veuillez préciser"
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </validation-provider>
                                      </b-form-radio>
                                    </b-form-radio-group>

                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="12">
                                <b-form-group
                                  label-cols-lg="12"
                                  label-class="regular-label"
                                  label="Mode d'hébergement au Bénin"
                                >
                                  <validation-provider
                                    v-slot="{ errors }"
                                    rules="required"
                                  >
                                    <b-form-radio-group
                                      v-model="
                                        form.sejourBenin.modeHebergement.radio
                                      "
                                    >
                                      <b-form-radio
                                        v-for="(
                                          item, index
                                        ) in modesHebergement"
                                        :key="'modesHebergementBenin' + index"
                                        name="modesHebergementBenin"
                                        :value="item"
                                      >
                                        {{ item.text }}
                                        <validation-provider
                                          v-if="
                                            item.code &&
                                            form.sejourBenin.modeHebergement
                                              .radio &&
                                            form.sejourBenin.modeHebergement
                                              .radio.code === item.code
                                          "
                                          v-slot="{ errors }"
                                          rules="required"
                                        >
                                          <b-form-input
                                            v-model="
                                              form.sejourBenin.modeHebergement
                                                .input
                                            "
                                            class="d-inline"
                                            type="text"
                                            placeholder="Veuillez préciser"
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </validation-provider>
                                      </b-form-radio>
                                    </b-form-radio-group>

                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </b-col>
                          <!-- CATEGORIE SOCIO-PROfESSIONNELLE/Socio-professional category  -->
                          <b-col md="12">
                            <b-form-group
                              label-cols-lg="12"
                              label-class="regular-label"
                              label="CATEGORIE SOCIO-PROfESSIONNELLE"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-radio-group
                                  v-model="form.categorieSocioPro.radio"
                                  disabled-field="disabled"
                                  class="ml-2"
                                >
                                  <b-form-radio
                                    v-for="(
                                      item, index
                                    ) in categorieSocioProList"
                                    :key="'categorieSocioPro' + index"
                                    name="categorieSocioPro"
                                    :value="item"
                                  >
                                    {{ item.text }}
                                    <validation-provider
                                      v-if="
                                        item.code &&
                                        form.categorieSocioPro.radio &&
                                        form.categorieSocioPro.radio.code ===
                                          item.code
                                      "
                                      v-slot="{ errors }"
                                      rules="required"
                                    >
                                      <b-form-input
                                        v-model="form.categorieSocioPro.input"
                                        class="d-inline"
                                        type="text"
                                        placeholder="Veuillez préciser la catégorie socio-professionnelle"
                                      />
                                      <small class="text-danger">{{
                                        errors[0]
                                      }}</small>
                                    </validation-provider>
                                  </b-form-radio>
                                </b-form-radio-group>
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <!-- age -->
                          <b-col md="6">
                            <b-form-group
                              label-cols-md="12"
                              label="Age du voyageur"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required|between:0,150"
                              >
                                <b-form-input
                                  v-model.number="form.age"
                                  type="number"
                                  placeholder="Veuillez saisir l'âge du voyageur"
                                  min="0"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>

                          <!-- sexe -->
                          <b-col lg="6">
                            <b-form-group label-cols-lg="12" label="Sexe">
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-radio-group
                                  v-model="form.sexe"
                                  :options="sexeList"
                                  class="ml-1"
                                  value-field="value"
                                  text-field="text"
                                  disabled-field="disabled"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>

                          <!-- lastname -->
                          <b-col md="6">
                            <b-form-group label-cols-md="12" label="Nom">
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required|alpha"
                              >
                                <b-form-input
                                  v-model="form.lastname"
                                  type="text"
                                  placeholder="Nom du voyageur"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <!-- firstname -->
                          <b-col md="6">
                            <b-form-group label-cols-lg="12" label="Prénom(s)">
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required|alpha"
                              >
                                <b-form-input
                                  v-model="form.firstname"
                                  type="text"
                                  placeholder="Prénom(s) du voyageur"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <!-- maidenname -->
                          <b-col v-if="form.sexe === 'Féminin'" md="12">
                            <b-form-group
                              label-cols-lg="12"
                              label-class="regular-label"
                              label="NOM DE JEUNE FILLE"
                            >
                              <!-- <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          > -->
                              <b-form-input
                                v-model="form.maidenname"
                                type="text"
                              />
                              <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                              <!-- </validation-provider> -->
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-card-body>
                    </b-card>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </div>
      </b-col>
    </b-row>

    <!-- Editions button -->
    <b-row v-if="showEditionButtons">
      <b-col cols="12" class="my-2 text-right">
        <div class="">
          <b-button variant="outline-secondary" @click="onCancel">
            Cancel
          </b-button>
          <b-button variant="primary" @click="submitForm"> Modifier </b-button>
        </div>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  // BListGroup,
  // BListGroupItem,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  // BFormRadio,
  // BForm,
  // BCardText,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
// import axios from 'axios'
import { mapActions, mapState } from "vuex";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import utilsService from "@/services/utils/utils.service";

// imports for validation
// const store = require('store')

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BCard,
    // BListGroup,
    // BListGroupItem,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    // BFormRadio,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // BCardText,
    // BForm,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  // computed: {
  //   ...mapState('params', {
  //     listPays: 'listPays',
  //     listMois: 'listMois',
  //     listAnnees: 'listAnnees',
  //   }),
  // },
  props: {
    inputData: {
      type: Object,
      default: () => null,
    },
    showEditionButtons: {
      type: Boolean,
      default: false,
    },
  }, //
  data() {
    return {
      email,
      required,
      // email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,
      pays: [],
      sexeList: [
        { text: "Masculin/Male", value: "Masculin" },
        { text: "Féminin/Female", value: "Féminin" },
      ],
      categorieSocioProList: [
        { text: "Libéral/indépendant", value: "Libéral/indépendant" },
        { text: "Employé/salarié", value: "Employé/salarié" },
        { text: "Retraité", value: "Retraité" },
        { text: "Autres (A préciser)", value: "Autres" },
      ],
      modesHebergement: [
        { text: "Hôtel et assimilés ", value: "Hôtel et assimilés " },
        { text: "Famille/Amis ", value: "Famille/Amis" },
        { text: "Autres", value: "Autres" },
      ],
      motifPrincipalVoyage: [
        {
          text: "Affaires et motifs professionnels",
          value: "Affaires et motifs professionnels",
        },
        {
          text: "Vacances, loisirs et détente",
          value: "Vacances, loisirs et détente",
        },
        {
          text: "Visites à des parents ou des amis",
          value: "Visites à des parents ou des amis",
        },
        { text: "Éducation et formation", value: "Éducation et formation" },
        { text: "Santé et soins médicaux", value: "Santé et soins médicaux" },
        { text: "Religion ou pèlerinage", value: "Religion ou pèlerinage" },
        { text: "Achats", value: "Achats" },
        { text: "Transit", value: "Transit" },
        { text: "Autres (A préciser)", value: "Autres" },
      ],
      autreModeHebergementBenin: "",
      autreModeHebergementEtranger: "",
      autreMotifVoyage: "",
      autreCategorieSocioPro: "",
      form: {
        age: "",
        commissariat: "",
        date: "",
        lastname: "",
        firstname: "",
        maidenname: "",
        sexe: "",
        nationalite: "",
        paysResidence: "",
        paysDestination: "",
        sejourEtranger: {
          dureeSejour: "",
          motifVoyage: "",
          modeHebergement: "",
        },
        sejourBenin: {
          dureeSejour: "",
          motifVoyage: "",
          modeHebergement: "",
        },
        categorieSocioPro: "",
      },
      today: moment().format("YYYY-MM-DD"),
    };
  },

  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        // await this.action_fetchTypesRoles()
        // this.getListEtablissement()
      },
    },
  },
  mounted() {
    // this.pays = this.listPays
    // this.mois = this.listMois
    // this.annees = this.listAnnees

    this.pays = utilsService.getListCountries();

    // this.loadQuartier();
    if (this.inputData) {
      this.form = this.inputData;
    } else if (this.$route.params.data) {
      this.form = this.$route.params.data;
    } else {
      // this.infos = this.validOutput
    }
  },

  methods: {
    // ...mapActions('params', {
    //   fetchAnnees: 'fetchAnnees',
    // }),
    onCancel() {
      this.$emit("onCancel", {});
    },

    // ...mapActions('etablissements', { action_findEtablissement: 'findEtablissement' }),
    // getListEtablissement() {
    //   this.action_findEtablissement()
    //     .then(response => {
    //       this.lites_hotels = response.data.resources
    //       console.log('list-etablissement', response, this.lites_hotels)
    //     })
    //     .catch(err => {
    //       // this.isLoadingTableData = false
    //       console.log('error-list-etablissement', err)
    //     })
    // },
    isBeninResident(country) {
      if (country && country.name) return country.name === "Benin";
      return country && country === "Benin";
    },
    submitForm() {
      // this.$emit('onFormSuccess', this.infos)
      this.$refs.formSortiesFrontieresTerrestres.validate().then((success) => {
        if (success) {
          // if (this.infos) {
          //   if (this.infos.annee.name) this.infos.annee = this.infos.annee.name
          //   if (this.infos.mois.name) this.infos.mois = this.infos.mois.name
          //   if (this.infos.departement.name) this.infos.departement = this.infos.departement.name
          //   if (this.infos.commune.name) this.infos.commune = this.infos.commune.name
          //   if (this.infos.arrondissement.name) this.infos.arrondissement = this.infos.arrondissement.name
          //   if (this.infos.nationalitePromoteur.name) this.infos.nationalitePromoteur = this.infos.nationalitePromoteur.name

          //   if (this.infos.telephone2 == '') delete this.infos.telephone2
          //   if (this.infos.telephone3 == '') delete this.infos.telephone3
          //   if (this.infos.agrementMinistere == 'non') delete this.infos.referenceAgrement
          // }
          const form = {
            ...this.form,
          };

          if (this.inputData) {
            this.$emit("onFormSuccess", form);
          } else {
            this.$emit("onFormSucess", {
              routerParams: { preview: true, data: this.form },
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/base/themes/bordered-layout.scss";
</style>
