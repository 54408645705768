<template>
  <div class="mb-5">
    <b-row class="my-2">
      <b-col md="12">
        <h1 class="text-center">
          Veuillez vous assurer que les informations entrées sont correctes puis
          enregistrez le formulaire
        </h1>
      </b-col>
    </b-row>
    <strong class="d-flex justify-content-center mb-1">
      Comissariat:<strong>{{
        infos && infos.commissariat && infos.commissariat.nom
      }}</strong>
    </strong>
    <b-card class="preview-document">
      <b-row>
        <b-col cols="12">
          <div v-if="infos" class="pl-1 pr-1">
            <div class="customborder1 p-2">
              <b-row>
                <b-col md="12" class="mb-1">
                  Date : <strong> {{ infos ? infos.date : "" }}</strong>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" class="mb-1">
                  Pays de résidence :
                  <strong>{{
                    infos.paysResidence && infos.paysResidence
                  }}</strong>
                </b-col>
                <b-col md="12" class="mb-1">
                  Nationalite :
                  <strong>{{ infos.nationalite && infos.nationalite }}</strong>
                </b-col>
                <b-col md="12" class="mb-1">
                  Pays de destination :
                  <strong>{{
                    infos.paysDestination && infos.paysDestination
                  }}</strong>
                </b-col>

                <b-col
                  v-if="!isBeninResident(infos.paysResidence)"
                  md="12"
                  class="mb-1"
                >
                  <div class="custom-label">
                    SÉJOUR AU BENIN POUR LES NON-RÉSIDENTS DU BENIN
                  </div>
                  <br />
                  <!-- Séjour au Bénin : -->
                  <div class="ml-2 mt-1">
                    <p>
                      Durée du séjour au Bénin :
                      <strong>{{
                        infos.sejourBenin && infos.sejourBenin.dureeSejour
                      }}</strong>
                    </p>
                    <p>
                      Motif principal du voyage au Bénin :
                      <strong>{{
                        infos.sejourBenin && infos.sejourBenin.motifVoyage
                      }}</strong>
                    </p>
                    <p>
                      Mode hébergement au Bénin :
                      <strong>{{
                        infos.sejourBenin && infos.sejourBenin.modeHebergement
                      }}</strong>
                    </p>
                  </div>
                </b-col>

                <b-col
                  v-if="isBeninResident(infos.paysResidence)"
                  md="12"
                  class="mb-1"
                >
                  <div>SÉJOUR A L’ÉTRANGER POUR LES RÉSIDENTS DU BENIN</div>
                  <br />
                  <!-- Séjour à l'étranger : -->
                  <div class="ml-2 mt-1">
                    <p>
                      Durée du séjour à l'étranger :
                      <strong>{{
                        infos.sejourEtranger && infos.sejourEtranger.dureeSejour
                      }}</strong>
                    </p>
                    <p>
                      Motif principal du voyage à l'étranger :
                      <strong>{{
                        infos.sejourEtranger && infos.sejourEtranger.motifVoyage
                      }}</strong>
                    </p>
                    <p>
                      Mode hébergement à l'étranger :
                      <strong>{{
                        infos.sejourEtranger &&
                        infos.sejourEtranger.modeHebergement
                      }}</strong>
                    </p>
                  </div>
                </b-col>
                <b-col md="12" class="mb-1">
                  Catégorie Socio-Professionnelle :
                  <strong>{{ infos.categorieSocioPro }}</strong>
                </b-col>
                <b-col md="6" class="mb-1">
                  Age : <strong>{{ infos.age }}</strong>
                </b-col>
                <b-col md="6" class="mb-1">
                  Sexe : <strong>{{ infos.sexe }}</strong>
                </b-col>
                <b-col md="6" class="mb-1">
                  Nom : <strong> {{ infos.lastname }}</strong>
                </b-col>
                <b-col md="6" class="mb-1">
                  Prenom(s) : <strong> {{ infos.firstname }}</strong>
                </b-col>
                <b-col v-if="infos.sexe !== 'Masculin'" md="12" class="mb-1">
                  Nom de la jeune fille:
                  <strong>{{ infos.maidenname }}</strong>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="px-1 mt-2">
        <b-col md="6">
          <div class="text-left">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="secondary"
              class="col-xl-4"
              @click="edit()"
            >
              Retourner sur le formulaire
            </b-button>
          </div>
        </b-col>
        <b-col md="6">
          <div class="text-right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="col-xl-4"
              @click="save()"
            >
              <span v-if="isSavingForm" class="d-flex align-items-center"
                ><div class="mr-2"><b-spinner /></div>

                <span class=""> Validation du formulaire... </span>
              </span>
              <span v-if="!isSavingForm"> Enregistrer les informations </span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <!-- modal backdrop -->
    <b-modal
      :ref="sectionEditionModalRef"
      scrollable
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      title="Modifier cette section"
      size="xl"
    >
      <!-- <b-card-text>
        <span>We've added the utility class</span>
        <code>'shadow'</code>
        <span>to the modal content for added effect.</span>
      </b-card-text> -->
      <component
        :is="sectionToEditComponent"
        :input-data="sectionToEditData"
        :show-edition-buttons="true"
        @onFormSuccess="onEditionFormSuccess($event)"
        @onCancel="hideSectionEditionModal"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormRadio,
  BForm,
  BCardText,
  VBTooltip,
  BImg,
  BSpinner,
  BModal,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapMutations } from "vuex";
import AppLogo from "@/components/AppLogo.vue";
import localstorageService from "@/services/localstorage/localstorage.service";
import utilsService from "@/services/utils/utils.service";

import formulaireStoreModule from "@/store/formulaire";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";
import FormSortieFrontieresTerrestres from "./sections/FormSortieFrontieresTerrestres.vue";

const validInput = {};
export default {
  components: {
    BCol,
    BRow,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormRadio,
    BForm,
    BCardText,
    BSpinner,
    vSelect,
    ToastificationContent,
    AppLogo,
    VBTooltip,
    BImg,
    BModal,

    // entete
    FormSortieFrontieresTerrestres,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  setup() {
    const requiredStoreModules = [
      { path: "formulaire", module: formulaireStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  data() {
    return {
      isSavingForm: false,
      infos: null,
      preview: null,
      isProcessingProvidedInput: true,
      sectionEditionModalRef: "edit-section-modal",
      sectionToEditComponent: null,
      sectionToEditData: null,
      editIconGreen: require("@/assets/images/icons/edit vert.png"),
      editIcon: require("@/assets/images/icons/edit.png"),
      currentUserId: localstorageService.getUserId(),
    };
  },
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer;
      if (rendererType === "sidebar-left") {
        return "layout-content-renderer-left";
      }
      if (rendererType === "sidebar-left-detached") {
        return "layout-content-renderer-left-detached";
      }
      return "layout-content-renderer-default";
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  mounted() {
    if (this.$route.params.data && this.$route.params.preview) {
      const { data, isEditionOperation } = this.$route.params;

      this.infos = {
        ...data,
        // ...(isEditionOperation && { editor: this.currentUserId }),
      };
      if (isEditionOperation) this.infos.editor = this.currentUserId;

      const userData = localstorageService.getUserData();
      // console.log('userData.role::: ', userData.role);
      if (userData.role.code === "commissaire") {
        // eslint-disable-next-line no-underscore-dangle
        this.infos.commissariat = userData;
      } else {
        this.infos.commissariat = userData.commissariatAssociated;
      }

      this.preview = this.$route.params.preview;
      // console.log(this.preview, this.infos)
      // console.log('ok')
    } else {
      // this.infos = validInput
      // console.log('ok')
      this.$router.push({
        name: "formulaires-create",
        params: { slug: this.$route.params.slug },
      });
    }
  },
  methods: {
    isBeninResident(country) {
      if (country && country.name) return country.name === "Bénin";
      return country && country === "Bénin";
    },
    ...mapMutations("auth", {
      callUserToRegister: "setter_callToRegister",
    }),
    ...mapActions("formulaire", {
      addFormulaire: "addFormulaire",
      updateFormulaire: "updateFormulaire",
    }),
    onEditionFormSuccess(evt) {
      this.infos = { ...this.infos, ...evt };
      this.hideSectionEditionModal();
      // console.log('onFormsuccess', evt)
    },
    // eslint-disable-next-line consistent-return
    getSectionToEditComponent(section) {
      return "FormSortieFrontieresTerrestres";
    },
    save() {
      const { isCommissaire, isAgentPolice } = utilsService.currentUserUtils();
      this.isSavingForm = true;
      const { isEditionOperation } = this.$route.params;
      const successMessage = !isEditionOperation
        ? "Formulaire enregistré avec succès"
        : "Opération réussie";
      const serviceMethode = isEditionOperation
        ? "updateFormulaire"
        : "addFormulaire";
      const { _id } = this.infos;
      delete this.infos._id;

      const isBeninResident = this.isBeninResident(this.infos.paysResidence);
      const sejourInfosKey = isBeninResident ? "sejourEtranger" : "sejourBenin";

      const { dureeSejour } = this.infos[sejourInfosKey];
      console.log("durreeSejour", dureeSejour);

      this.infos[sejourInfosKey].dureeSejour = dureeSejour;
      // this.infos[
      //   isBeninResident ? "sejourBenin" : "sejourEtranger"
      // ].dureeSejour = "";

      // console.log('this.infos :>> ', this.infos)
      delete this.infos.quartier;
      const data = {
        formType: "forms-frontiere-sortie-voyageurs",
        ...(isEditionOperation && { id: _id }),
        data: {
          ...this.infos,
          paysResidence: this.infos.paysResidence,
          nationalite: this.infos.nationalite,
          paysDestination: this.infos.paysDestination,
          age: parseInt(this.infos.age, 10),
        },
      };

      const userData = localstorageService.getUserData();
      if (userData._id) {
        data.data.auteur = userData._id;
        data.data.auteurRole = userData.role._id;
        if (isCommissaire) {
          data.data.departement = userData.departement;
          data.data.commune = userData.commune;
          data.data.arrondissement = userData.arrondissement;
        }
        if (isAgentPolice) {
          data.data.departement = userData.commissariatAssociated.departement;
          data.data.commune = userData.commissariatAssociated.commune;
          data.data.arrondissement =
            userData.commissariatAssociated.arrondissement;
        }
      } else {
        delete data.data.auteur;
      }

      data.data.commissariat = this.infos.commissariat._id;

      console.log("datasejour", data);

      this[serviceMethode](data)
        .then((response) => {
          // console.log('============ response create form: ===========',response,)
          if (response.status === 200) {
            if (
              this.$store.state.auth &&
              this.$store.state.auth.isAuthenticated
            ) {
              // this.$router.push({
              //   name: 'formulaires-list',
              //   params: { slug: this.$route.params.slug },
              // })
              // this.$router.push({
              //   name: 'dashboard',
              // })
              // let selectedForm = utilsService.getFormulaireBySlug(this.$route.params.slug)
              // console.log('selectedForm: 🔵🔴🔵🔴 ', selectedForm)
              // const { params } = selectedForm.createRouteProps
              // this.$router.push({
              //   ...selectedForm.createRouteProps,
              //   params: {
              //     ...params,
              //   },
              // })

              this.$router.push({
                name: "dashboard",
              });

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: successMessage,
                  icon: "CheckIcon",
                  variant: "success",
                  // text: 'Bienvenue !',
                },
              });
            } else {
              this.$router.push({
                name: "dashboard",
              });
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: successMessage,
                  icon: "CheckIcon",
                  variant: "success",
                  // text: 'Bienvenue !',
                },
              });
            }
            // return
            const callToRegisterFormData = {
              nom: this.infos.firstname,
              prenom: this.infos.lastname,
              email: "",
              telephone: "",
            };
            this.callUserToRegister({
              status: true,
              data: callToRegisterFormData,
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Une erreur est suvenue, veuillez rééssayer",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
          this.isSavingForm = false;
        })
        .catch((error) => {
          this.isSavingForm = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                error.message || "Une erreur est suvenue, veuillez rééssayer",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    showSectionEditionModal() {
      this.$refs[this.sectionEditionModalRef].show();
    },
    hideSectionEditionModal() {
      this.$refs[this.sectionEditionModalRef].hide();
    },
    editSection(section) {
      this.sectionToEditComponent = this.getSectionToEditComponent(section);
      this.sectionToEditData = JSON.parse(JSON.stringify(this.infos));

      this.showSectionEditionModal();
    },
    edit() {
      this.$router.push({
        name: "formulaires-create",
        params: { slug: this.$route.params.slug, data: this.infos },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/base/themes/bordered-layout.scss";

.customborder1 {
  border: 1px solid rgba(0, 0, 0, 0.3);
  // padding: 15px 35px;
}
.customborder2 {
  border: 1px solid rgba(0, 0, 0, 0.3);
  // padding: 0 35px 15px;
}

table {
  td,
  th,
  tr {
    text-align: left;
  }
}
thead {
  th {
    background-color: #0e6258 !important;
    color: white;
  }
}
</style>
