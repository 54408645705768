var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formSortiesFrontieresTerrestres"},[_c('b-row',[_c('b-col',{staticClass:"pb-2",attrs:{"lg":"12"}},[_c('div',[_c('b-card',[_c('b-card-body',[_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label"},[_vm._v(" Commissariat de police "),_c('small',[_vm._v("(Réservé à l’agent de police)")])]),_c('b-form-input',{attrs:{"type":"text","placeholder":""},model:{value:(_vm.form.commissariat),callback:function ($$v) {_vm.$set(_vm.form, "commissariat", $$v)},expression:"form.commissariat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"pb-2",attrs:{"lg":"12"}},[_c('div',[_c('b-card',[_c('b-card-body',[(false)?_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label"},[_vm._v(" Commissariat de police "),_c('small',[_vm._v("(Réservé à l’agent de police)")])]),_c('b-form-input',{attrs:{"type":"text","placeholder":""},model:{value:(_vm.form.commissariat),callback:function ($$v) {_vm.$set(_vm.form, "commissariat", $$v)},expression:"form.commissariat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,230940324)})],1)],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"12","label":"Date de sortie du voyageur"}},[_c('validation-provider',{attrs:{"rules":"required|beforeOrToday"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"lang":"fr-CA","type":"date","placeholder":"Veuillez entrer la date à laquelle vous êtes entrées","max":_vm.today},model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"Pays de résidence"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SelectCountriesList',{attrs:{"inputValue":_vm.form.paysResidence,"placeholder":'Sélectionnez le pays de résidence'},model:{value:(_vm.form.paysResidence),callback:function ($$v) {_vm.$set(_vm.form, "paysResidence", $$v)},expression:"form.paysResidence"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"Nationalité"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SelectCountriesList',{attrs:{"inputValue":_vm.form.nationalite,"placeholder":'Sélectionnez le pays de votre nationalité'},model:{value:(_vm.form.nationalite),callback:function ($$v) {_vm.$set(_vm.form, "nationalite", $$v)},expression:"form.nationalite"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label-class":"regular-label","label":"PAYS DE DESTINATION"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SelectCountriesList',{attrs:{"inputValue":_vm.form.paysDestination,"placeholder":'Sélectionnez le pays de destination'},model:{value:(_vm.form.paysDestination),callback:function ($$v) {_vm.$set(_vm.form, "paysDestination", $$v)},expression:"form.paysDestination"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(false)?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"Mode d'hébergement au benin"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{model:{value:(_vm.form.modeHebergementBenin.radio),callback:function ($$v) {_vm.$set(_vm.form.modeHebergementBenin, "radio", $$v)},expression:"form.modeHebergementBenin.radio"}},_vm._l((_vm.modesHebergement),function(item,index){return _c('b-form-radio',{key:'modeHebergementBenin' + index,attrs:{"name":"modeHebergementBenin","value":item}},[_vm._v(" "+_vm._s(item.text)+" "),(
                                      item.code &&
                                      _vm.form.modeHebergementBenin.radio &&
                                      _vm.form.modeHebergementBenin.radio.code ===
                                        item.code
                                    )?_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-input',{staticClass:"d-inline",attrs:{"type":"text","placeholder":"Veuillez préciser"},model:{value:(
                                        _vm.form.modeHebergementBenin.input
                                      ),callback:function ($$v) {_vm.$set(_vm.form.modeHebergementBenin, "input", $$v)},expression:"\n                                        form.modeHebergementBenin.input\n                                      "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e()],1)}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,813463479)})],1)],1):_vm._e(),(_vm.isBeninResident(_vm.form.paysResidence))?_c('b-col',{attrs:{"md":"12"}},[_c('div',[_vm._v(" SÉJOUR A L’ÉTRANGER POUR LES RÉSIDENTS DU BENIN ")]),_c('b-row',{staticClass:"ml-2"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"Durée de séjour à l'étranger"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                      var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only"}],attrs:{"placeholder":"Durée de séjour à l'étranger","type":"number"},model:{value:(
                                      _vm.form.sejourEtranger.dureeSejour
                                    ),callback:function ($$v) {_vm.$set(_vm.form.sejourEtranger, "dureeSejour", _vm._n($$v))},expression:"\n                                      form.sejourEtranger.dureeSejour\n                                    "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,202465790)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"Motif principal du voyage à l'étranger"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-radio-group',{model:{value:(
                                      _vm.form.sejourEtranger.motifVoyage.radio
                                    ),callback:function ($$v) {_vm.$set(_vm.form.sejourEtranger.motifVoyage, "radio", $$v)},expression:"\n                                      form.sejourEtranger.motifVoyage.radio\n                                    "}},_vm._l((_vm.motifPrincipalVoyage),function(item,index){return _c('b-form-radio',{key:'motifPrincipalVoyageEtranger' + index,attrs:{"name":"motifPrincipalVoyageEtranger","value":item}},[_vm._v(" "+_vm._s(item.text)+" "),(
                                          item.code &&
                                          _vm.form.sejourEtranger.motifVoyage
                                            .radio &&
                                          _vm.form.sejourEtranger.motifVoyage
                                            .radio.code === item.code
                                        )?_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('b-form-input',{staticClass:"d-inline",attrs:{"type":"text","placeholder":"Veuillez préciser"},model:{value:(
                                            _vm.form.sejourEtranger.motifVoyage
                                              .input
                                          ),callback:function ($$v) {_vm.$set(_vm.form.sejourEtranger.motifVoyage
                                              , "input", $$v)},expression:"\n                                            form.sejourEtranger.motifVoyage\n                                              .input\n                                          "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e()],1)}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,196944479)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"Mode d'hébergement à l'étranger"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                              var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"disabled-field":"disabled"},model:{value:(
                                      _vm.form.sejourEtranger.modeHebergement
                                        .radio
                                    ),callback:function ($$v) {_vm.$set(_vm.form.sejourEtranger.modeHebergement
                                        , "radio", $$v)},expression:"\n                                      form.sejourEtranger.modeHebergement\n                                        .radio\n                                    "}},_vm._l((_vm.modesHebergement),function(item,index){return _c('b-form-radio',{key:'modesHebergementEtranger' + index,attrs:{"name":"modesHebergementEtranger","value":item}},[_vm._v(" "+_vm._s(item.text)+" "),(
                                          item.code &&
                                          _vm.form.sejourEtranger.modeHebergement
                                            .radio &&
                                          _vm.form.sejourEtranger.modeHebergement
                                            .radio.code === item.code
                                        )?_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('b-form-input',{staticClass:"d-inline",attrs:{"type":"text","placeholder":"Veuillez préciser"},model:{value:(
                                            _vm.form.sejourEtranger
                                              .modeHebergement.input
                                          ),callback:function ($$v) {_vm.$set(_vm.form.sejourEtranger
                                              .modeHebergement, "input", $$v)},expression:"\n                                            form.sejourEtranger\n                                              .modeHebergement.input\n                                          "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e()],1)}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,841448888)})],1)],1)],1)],1):_vm._e(),(!_vm.isBeninResident(_vm.form.paysResidence))?_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"custom-label"},[_vm._v(" SÉJOUR AU BENIN POUR LES NON-RÉSIDENTS DU BENIN ")]),_c('b-row',{staticClass:"ml-2"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"Durée de séjour à l'étranger"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                              var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only"}],attrs:{"placeholder":"Durée de séjour à l'étranger","type":"number"},model:{value:(
                                      _vm.form.sejourBenin.dureeSejour
                                    ),callback:function ($$v) {_vm.$set(_vm.form.sejourBenin, "dureeSejour", _vm._n($$v))},expression:"\n                                      form.sejourBenin.dureeSejour\n                                    "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4090586252)})],1)],1),(_vm.form.sejourBenin)?_c('b-col',{attrs:{"md":"10"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label-class":"regular-label","label":"MOTIF PRINCIPAL DU VOYAGE AU BENIN"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-radio-group',{model:{value:(
                                      _vm.form.sejourBenin.motifVoyage.radio
                                    ),callback:function ($$v) {_vm.$set(_vm.form.sejourBenin.motifVoyage, "radio", $$v)},expression:"\n                                      form.sejourBenin.motifVoyage.radio\n                                    "}},_vm._l((_vm.motifPrincipalVoyage),function(item,index){return _c('b-form-radio',{key:'motifPrincipalVoyageBenin' + index,attrs:{"name":"motifPrincipalVoyageBenin","value":item}},[_vm._v(" "+_vm._s(item.text)+" "),(
                                          item.code &&
                                          _vm.form.sejourBenin.motifVoyage
                                            .radio &&
                                          _vm.form.sejourBenin.motifVoyage.radio
                                            .code === item.code
                                        )?_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('b-form-input',{staticClass:"d-inline",attrs:{"type":"text","placeholder":"Veuillez préciser"},model:{value:(
                                            _vm.form.sejourBenin.motifVoyage.input
                                          ),callback:function ($$v) {_vm.$set(_vm.form.sejourBenin.motifVoyage, "input", $$v)},expression:"\n                                            form.sejourBenin.motifVoyage.input\n                                          "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e()],1)}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1549964941)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label-class":"regular-label","label":"Mode d'hébergement au Bénin"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                          var errors = ref.errors;
return [_c('b-form-radio-group',{model:{value:(
                                      _vm.form.sejourBenin.modeHebergement.radio
                                    ),callback:function ($$v) {_vm.$set(_vm.form.sejourBenin.modeHebergement, "radio", $$v)},expression:"\n                                      form.sejourBenin.modeHebergement.radio\n                                    "}},_vm._l((_vm.modesHebergement),function(item,index){return _c('b-form-radio',{key:'modesHebergementBenin' + index,attrs:{"name":"modesHebergementBenin","value":item}},[_vm._v(" "+_vm._s(item.text)+" "),(
                                          item.code &&
                                          _vm.form.sejourBenin.modeHebergement
                                            .radio &&
                                          _vm.form.sejourBenin.modeHebergement
                                            .radio.code === item.code
                                        )?_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('b-form-input',{staticClass:"d-inline",attrs:{"type":"text","placeholder":"Veuillez préciser"},model:{value:(
                                            _vm.form.sejourBenin.modeHebergement
                                              .input
                                          ),callback:function ($$v) {_vm.$set(_vm.form.sejourBenin.modeHebergement
                                              , "input", $$v)},expression:"\n                                            form.sejourBenin.modeHebergement\n                                              .input\n                                          "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e()],1)}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3793293615)})],1)],1)],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label-class":"regular-label","label":"CATEGORIE SOCIO-PROfESSIONNELLE"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                              var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"ml-2",attrs:{"disabled-field":"disabled"},model:{value:(_vm.form.categorieSocioPro.radio),callback:function ($$v) {_vm.$set(_vm.form.categorieSocioPro, "radio", $$v)},expression:"form.categorieSocioPro.radio"}},_vm._l((_vm.categorieSocioProList),function(item,index){return _c('b-form-radio',{key:'categorieSocioPro' + index,attrs:{"name":"categorieSocioPro","value":item}},[_vm._v(" "+_vm._s(item.text)+" "),(
                                      item.code &&
                                      _vm.form.categorieSocioPro.radio &&
                                      _vm.form.categorieSocioPro.radio.code ===
                                        item.code
                                    )?_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-input',{staticClass:"d-inline",attrs:{"type":"text","placeholder":"Veuillez préciser la catégorie socio-professionnelle"},model:{value:(_vm.form.categorieSocioPro.input),callback:function ($$v) {_vm.$set(_vm.form.categorieSocioPro, "input", $$v)},expression:"form.categorieSocioPro.input"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e()],1)}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-cols-md":"12","label":"Age du voyageur"}},[_c('validation-provider',{attrs:{"rules":"required|between:0,150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","placeholder":"Veuillez saisir l'âge du voyageur","min":"0"},model:{value:(_vm.form.age),callback:function ($$v) {_vm.$set(_vm.form, "age", _vm._n($$v))},expression:"form.age"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"Sexe"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"ml-1",attrs:{"options":_vm.sexeList,"value-field":"value","text-field":"text","disabled-field":"disabled"},model:{value:(_vm.form.sexe),callback:function ($$v) {_vm.$set(_vm.form, "sexe", $$v)},expression:"form.sexe"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-cols-md":"12","label":"Nom"}},[_c('validation-provider',{attrs:{"rules":"required|alpha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":"Nom du voyageur"},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"Prénom(s)"}},[_c('validation-provider',{attrs:{"rules":"required|alpha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":"Prénom(s) du voyageur"},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.form.sexe === 'Féminin')?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label-class":"regular-label","label":"NOM DE JEUNE FILLE"}},[_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.form.maidenname),callback:function ($$v) {_vm.$set(_vm.form, "maidenname", $$v)},expression:"form.maidenname"}})],1)],1):_vm._e()],1)],1)],1)],1)])],1)],1)],1)],1)])],1),(_vm.showEditionButtons)?_c('b-row',[_c('b-col',{staticClass:"my-2 text-right",attrs:{"cols":"12"}},[_c('div',{},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.onCancel}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.submitForm}},[_vm._v(" Modifier ")])],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }